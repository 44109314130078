import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import React, { useState } from "react";
import frontBodyImg from "../assets/body_front.png";
import backBodyImg from "../assets/body_back.png";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function Injuryv2() {
  const [tabValue, setTabValue] = useState("front");
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: "#47545B", color: "white" }}
      >
        <Typography variant="h6">Injury</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="fullWidth"
              onChange={(evt, newVal) => {
                setTabValue(newVal);
              }}
            >
              <Tab label="Front" value="front" />
              <Tab label="Back" value="back" />
            </TabList>
          </Box>
          <TabPanel value="front">
            <img src={frontBodyImg} width="100%" alt="Front body" />
          </TabPanel>
          <TabPanel value="back">
            <img src={backBodyImg} width="100%" alt="Back body" />
          </TabPanel>
        </TabContext>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
              <Typography variant="h6">TQ R ARM</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="TQ Soft-T Wide"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                variant="standard"
                label="Other"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AccessTimeIcon />}
              >
                Timestamp
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
              <Typography variant="h6">TQ L ARM</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="TQ Soft-T Wide"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                variant="standard"
                label="Other"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AccessTimeIcon />}
              >
                Timestamp
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
              <Typography variant="h6">TQ R LEG</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="TQ Soft-T Wide"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                variant="standard"
                label="Other"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AccessTimeIcon />}
              >
                Timestamp
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
              <Typography variant="h6">TQ L LEG</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="TQ Soft-T Wide"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                variant="standard"
                label="Other"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AccessTimeIcon />}
              >
                Timestamp
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
