import {
  Radar as RadarIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { red, yellow, green } from "@mui/material/colors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import dayjs from "dayjs";

export default function BasincInfo() {
  const [showRFID, setShowRFID] = useState(false);
  return (
    <React.Fragment>
      <Dialog
        onClose={() => {
          setShowRFID(false);
        }}
        open={showRFID}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <DialogContentText>Scanning RFID tag</DialogContentText>
          <CircularProgress
            variant="indeterminate"
            size={64}
            thickness={5}
          ></CircularProgress>
        </DialogContent>
      </Dialog>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#47545B", color: "white" }}
        >
          <Typography variant="h6">ID/time/EVAC prio</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
            <Button
              fullWidth
              startIcon={<RadarIcon />}
              variant="contained"
              size="large"
              sx={{ marginBottom: 2 }}
              onClick={() => {
                setShowRFID(true);
              }}
            >
              Scan RFID
            </Button>
            <TextField fullWidth label="Name" sx={{ marginBottom: 1 }} />
            <DatePicker
              label="Date of birth"
              slotProps={{ textField: { fullWidth: true } }}
            />
            <FormControl>
              <FormLabel id="gender_label">Gender</FormLabel>
              <RadioGroup row aria-labelledby="gender_label">
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Service/Unit"
              sx={{ marginBottom: 1, marginTop: 1 }}
              fullWidth
            />
            <TextField multiline fullWidth label="Allergies" rows={2} />
          </Paper>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
            <Typography avriant="h6">Time</Typography>
            <DateTimePicker
              defaultValue={dayjs()}
              label="At time"
              views={["day", "month", "year", "hours", "minutes"]}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Paper>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
            <FormControl>
              <FormLabel id="evac_label">EVAC Prio</FormLabel>
              <RadioGroup aria-labelledby="evac_label">
                <FormControlLabel
                  value="urgent"
                  control={
                    <Radio
                      sx={{
                        color: red[800],
                        "&.Mui-checked": {
                          color: red[600],
                        },
                      }}
                    />
                  }
                  label="Urgent"
                />
                <FormControlLabel
                  value="priority"
                  control={
                    <Radio
                      sx={{
                        color: yellow[500],
                        "&.Mui-checked": {
                          color: yellow[600],
                        },
                      }}
                    />
                  }
                  label="Priority"
                />
                <FormControlLabel
                  value="routine"
                  control={
                    <Radio
                      sx={{
                        color: green[500],
                        "&.Mui-checked": {
                          color: green[600],
                        },
                      }}
                    />
                  }
                  label="Routine"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
            <FormControl>
              <FormLabel id="evac_label">Situation</FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <RadioGroup>
                    <FormControlLabel control={<Checkbox />} label="Friendly" />
                    <FormControlLabel control={<Checkbox />} label="CIV" />
                    <FormControlLabel control={<Checkbox />} label="Unknown" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RadioGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Battle Injury"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Non-battle Injury"
                    />
                    <FormControlLabel control={<Checkbox />} label="Disease" />
                    <FormControlLabel control={<Checkbox />} label="CBRN" />
                  </RadioGroup>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
