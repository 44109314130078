import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import React, { useState } from "react";

export default function MechanismInjury() {
  const [mechStates, setMechStates] = useState({
    artillery: false,
    blunt: false,
    burn: false,
    fall: false,
    grenade: false,
    gsw: false,
    ied: false,
    landmine: false,
    mvc: false,
    rpg: false,
  });
  const handleChange = (evt) => {
    setMechStates({
      ...mechStates,
      [evt.target.name]: evt.target.checked,
    });
  };
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: "#47545B", color: "white" }}
      >
        <Typography variant="h6">Mechanism</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
          <FormControl>
            <RadioGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.artillery}
                    onChange={handleChange}
                    name="artillery"
                  />
                }
                label="Artillery"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.blunt}
                    onChange={handleChange}
                    name="blunt"
                  />
                }
                label="Blunt"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.burn}
                    onChange={handleChange}
                    name="burn"
                  />
                }
                label="Burn"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.fall}
                    onChange={handleChange}
                    name="fall"
                  />
                }
                label="Fall"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.grenade}
                    onChange={handleChange}
                    name="grenade"
                  />
                }
                label="Grenade"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.gsw}
                    onChange={handleChange}
                    name="gsw"
                  />
                }
                label="GSW"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.ied}
                    onChange={handleChange}
                    name="ied"
                  />
                }
                label="IED"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.landmine}
                    onChange={handleChange}
                    name="landmine"
                  />
                }
                label="Landmine"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.mvc}
                    onChange={handleChange}
                    name="mvc"
                  />
                }
                label="MVC"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mechStates.rpg}
                    onChange={handleChange}
                    name="rpg"
                  />
                }
                label="RPG"
              />
            </RadioGroup>
          </FormControl>
          <TextField variant="standard" label="Other" fullWidth />
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}
