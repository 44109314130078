import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Vaccines as VaccinesIcon,
  Bloodtype as BloodtypeIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
} from "@mui/icons-material";
import React, { useState } from "react";

export default function Meds() {
  const [medDia, setMedDia] = useState(false);
  const [medType, setMedType] = useState("");
  const [medAdm, setmedAdm] = useState("");
  const [medAmount, setMedAmount] = useState("");
  const mockList = [
    {
      medName: "Paracetamol",
      medTrueName: "Paracetamol®",
      medDose: "1000 mg",
      medAdm: "",
    },
    {
      medName: "Eterocoxib",
      medTrueName: "Arcoxia®",
      medDose: "120 mg",
      medAdm: "",
    },
    {
      medName: "Fentanyl",
      medTrueName: "Actiq®",
      medDose: "800 mcg",
      medAdm: "",
    },
    {
      medName: "BM Esketamin",
      medTrueName: "Ketanest®",
      medDose: "12,5 mg",
      medAdm: "IO",
    },
    {
      medName: "BM Esketamin",
      medTrueName: "Ketanest®",
      medDose: "25 mg",
      medAdm: "IV",
    },
    { medName: "Morphine", medTrueName: "", medDose: "5 mg", medAdm: "IV" },
    {
      medName: "BM Traneksamacid",
      medTrueName: "Cyklokapron®",
      medDose: "2 g",
      medAdm: "",
    },
    {
      medName: "BM Freezedried plasma",
      medTrueName: "",
      medDose: "1 unit",
      medAdm: "",
    },
  ];
  return (
    <React.Fragment>
      <Dialog
        open={medDia}
        onClose={() => {
          setMedDia(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add medication:</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="pulse-location-label">Medication type:</InputLabel>
            <Select
              labelId="pulse-location-label"
              id="pulse-location"
              fullWidth
              label="Medication type:"
              value={medType}
              onChange={(evt) => {
                setMedType(evt.target.value);
              }}
              margin="dense"
            >
              {mockList.map((elm, index) => (
                <MenuItem value={JSON.stringify(elm)} key={index}>
                  {elm.medName}({elm.medTrueName}), {elm.medDose} {elm.medAdm}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setMedDia(false);
            }}
          >
            Add medication
          </Button>
        </DialogContent>
      </Dialog>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#47545B", color: "white" }}
        >
          <Typography variant="h6">Treatments - Meds</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            fullWidth
            variant="contained"
            sx={{ marginBottom: 1 }}
            onClick={() => {
              setMedDia(true);
            }}
          >
            Add medication
          </Button>
          <Typography variant="h6">Summary:</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <VaccinesIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <React.Fragment>
                    <Typography variant="button">Paracetamol</Typography>
                    <Typography variant="body2">(Paracetamol®)</Typography>
                    <Typography variant="body2">1000 mg</Typography>
                  </React.Fragment>
                }
                secondary={
                  <Typography variant="caption">17.04-23 08:53</Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BloodtypeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <React.Fragment>
                    <Typography variant="button">Paracetamol</Typography>
                    <Typography variant="body2">(Paracetamol®)</Typography>
                    <Typography variant="body2">1000 mg</Typography>
                  </React.Fragment>
                }
                secondary={
                  <Typography variant="caption">17.04-23 08:53</Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SentimentSatisfiedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <React.Fragment>
                    <Typography variant="button">Paracetamol</Typography>
                    <Typography variant="body2">(Paracetamol®)</Typography>
                    <Typography variant="body2">1000 mg</Typography>
                  </React.Fragment>
                }
                secondary={
                  <Typography variant="caption">17.04-23 08:53</Typography>
                }
              />
            </ListItem>
            <Divider />
          </List>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
