import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import React, { useState } from "react";
import dayjs from "dayjs";

export default function SignsAndSymptoms() {
  const [showReg, setShowReg] = useState(false);
  const [medRegList, setMedRegList] = useState([]);
  const [pulse, setPulse] = useState("");
  const [pulseLoc, setPulseLoc] = useState("");
  const [bp, setBp] = useState("");
  const [rr, setRr] = useState("");
  const [sao2, setSao2] = useState("");
  const [avpu, setAvpu] = useState("");
  const [pain, setPain] = useState("");
  const handleAddMeasure = () => {
    setMedRegList([
      ...medRegList,
      {
        time: dayjs(),
        pulse: pulse,
        pulseloc: pulseLoc,
        bp: bp,
        rr: rr,
        sao2: sao2,
        avpu: avpu,
        pain: pain,
      },
    ]);
    console.log(medRegList);
  };
  return (
    <React.Fragment>
      <Dialog
        open={showReg}
        onClose={() => {
          setShowReg(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Register Vital Parameters</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Pulse"
            type="number"
            fullWidth
            value={pulse}
            onChange={(evt) => {
              setPulse(evt.target.value);
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="pulse-location-label">Pulse Location</InputLabel>
            <Select
              labelId="pulse-location-label"
              id="pulse-location"
              fullWidth
              label="Pulse Location"
              value={pulseLoc}
              onChange={(evt) => {
                setPulseLoc(evt.target.value);
              }}
              margin="dense"
            >
              <MenuItem value="Carotid">Carotid</MenuItem>
              <MenuItem value="Femoral">Femoral</MenuItem>
              <MenuItem value="Radial">Radial</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Blood Pressure"
            value={bp}
            onChange={(evt) => {
              setBp(evt.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Respiration Rate"
            type="number"
            value={rr}
            onChange={(evt) => {
              setRr(evt.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Pulse Ox % O2 Sat"
            type="number"
            value={sao2}
            onChange={(evt) => {
              setSao2(evt.target.value);
            }}
            fullWidth
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="avpu-scale-label">AVPU</InputLabel>
            <Select
              labelId="avpu-scale-label"
              id="avpu-scale"
              fullWidth
              label="AVPU"
              margin="dense"
              value={avpu}
              onChange={(evt) => {
                setAvpu(evt.target.value);
              }}
            >
              <MenuItem sx={{ backgroundColor: "green" }} value="Alert">
                Alert
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "red" }} value="Verbal">
                Verbal
              </MenuItem>
              <MenuItem value="Pain">Pain</MenuItem>
              <MenuItem value="Unresponsive">Unresponsive</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Pain Scale (0-10)"
            type="number"
            fullWidth
            value={pain}
            onChange={(evt) => {
              setPain(evt.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setShowReg(false);
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleAddMeasure}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#47545B", color: "white" }}
        >
          <Typography variant="h6">Sign and Symptoms</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            fullWidth
            variant="contained"
            sx={{ marginBottom: 2 }}
            onClick={() => {
              setShowReg(true);
            }}
          >
            New measurement
          </Button>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {medRegList.map((elm, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <React.Fragment>
                        <Typography variant="body1">
                          <strong>Pulse:</strong> {elm.pulse || "-"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Pulse Loc:</strong> {elm.pulseLoc || "-"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>BP:</strong> {elm.bp || "-"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>RR:</strong> {elm.rr || "-"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>SaO2:</strong> {elm.sao2 || "-"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>AVPU:</strong> {elm.avpu || "-"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Pain:</strong> {elm.pain || "-"}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <Typography variant="caption">
                        {dayjs(elm.time).format("DD/MM/YYYY HH:ss")}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
