import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export default function FirstResponder() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: "#47545B", color: "white" }}
      >
        <Typography variant="h6">First Responder</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          fullWidth
          variant="outlined"
          label="Signature First Responder"
        />
      </AccordionDetails>
    </Accordion>
  );
}
