import { AppBar, Container, Divider, Toolbar, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BasincInfo from "./components/BasincInfo";
import MechanismInjury from "./components/MechanismInjury";
import BliksundIcon from "./assets/bliksundicon.svg";
import SignsAndSymptoms from "./components/SignsAndSymptoms";
import Injuryv2 from "./components/Injuryv2";
import Treatments from "./components/Treatments";
import Meds from "./components/Meds";
import Notes from "./components/Notes";
import FirstResponder from "./components/FirstResponder";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg" disableGutters sx={{ minHeight: "100vh" }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#161A1C", color: "white" }}
        >
          <Toolbar disableGutters>
            <img src={BliksundIcon} style={{ height: 80, marginRight: 0 }} />
            <div style={{ flexGrow: 1 }}>
              <Typography variant="h6">EWA Forward</Typography>
              <Typography variant="subtitle1">TCCC</Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Divider />
        <BasincInfo />
        <MechanismInjury />
        <Injuryv2 />
        <SignsAndSymptoms />
        <Treatments />
        <Meds />
        <Notes />
        <FirstResponder />
      </Container>
    </LocalizationProvider>
  );
}

export default App;
