import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export default function Notes() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: "#47545B", color: "white" }}
      >
        <Typography variant="h6">Notes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField fullWidth multiline rows={8} label="Notes" />
      </AccordionDetails>
    </Accordion>
  );
}
