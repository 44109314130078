import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import React, { useState } from "react";
import dayjs from "dayjs";

export default function Treatments() {
  const [fluid, setFluid] = useState(0);
  const [fluidDialog, setFluidDialog] = useState(false);
  const [fluidType, setFluidType] = useState("");
  const [fluidList, setFluidList] = useState([]);
  const handleAddFluid = () => {
    if (fluid && fluidType) {
      setFluidList([
        ...fluidList,
        { fluidtype: fluidType, amount: fluid, time: dayjs() },
      ]);
      setFluidDialog(false);
      setFluidType("");
      setFluid(0);
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={fluidDialog}
        onClose={() => {
          setFluidDialog(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Register Fluid / Blood</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="fluid-location-label">Fluid type:</InputLabel>
            <Select
              labelId="fluid-location-label"
              id="pulse-location"
              fullWidth
              label="Fluid type:"
              value={fluidType}
              onChange={(evt) => {
                setFluidType(evt.target.value);
              }}
              margin="dense"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="Fluid">Fluid</MenuItem>
              <MenuItem value="Blood">Blood</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            variant="outlined"
            label="Volume (ml)"
            sx={{ marginBottom: 2 }}
            value={fluid}
            onChange={(evt) => {
              setFluid(evt.target.value);
            }}
          />
          <Button fullWidth variant="contained" onClick={handleAddFluid}>
            Add
          </Button>
        </DialogContent>
      </Dialog>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#47545B", color: "white" }}
        >
          <Typography variant="h6">Treatments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
            <Typography variant="h6">C - Catastrophic Haemorrhage</Typography>
            <FormControl>
              <FormLabel id="march_m_label">Tourniquet</FormLabel>
              <RadioGroup>
                <FormControlLabel control={<Checkbox />} label="Extremity" />
                <FormControlLabel control={<Checkbox />} label="Junctional" />
                <FormControlLabel control={<Checkbox />} label="Truncal" />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="march_m_label">Dressing</FormLabel>
              <RadioGroup>
                <FormControlLabel control={<Checkbox />} label="Extremity" />
                <FormControlLabel control={<Checkbox />} label="Junctional" />
                <FormControlLabel control={<Checkbox />} label="Truncal" />
              </RadioGroup>
            </FormControl>
            <TextField
              variant="standard"
              label="Note"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Paper>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
            <Typography variant="h6">A - Airway</Typography>
            <FormControl>
              <RadioGroup>
                <FormControlLabel control={<Checkbox />} label="Intact" />
                <FormControlLabel control={<Checkbox />} label="NPA" />
                <FormControlLabel control={<Checkbox />} label="CRIC" />
                <FormControlLabel control={<Checkbox />} label="ET-Tube" />
                <FormControlLabel control={<Checkbox />} label="SGA" />
              </RadioGroup>
            </FormControl>
            <TextField
              variant="standard"
              label="Note"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Paper>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
            <Typography variant="h6">B - Breathing</Typography>
            <FormControl>
              <RadioGroup>
                <FormControlLabel control={<Checkbox />} label="O2" />
                <FormControlLabel control={<Checkbox />} label="Needle - D" />
                <FormControlLabel control={<Checkbox />} label="Chest-Tube" />
                <FormControlLabel control={<Checkbox />} label="Chest-Seal" />
              </RadioGroup>
            </FormControl>
            <TextField
              variant="standard"
              label="Note"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Paper>
          <Paper elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
            <Typography variant="h6">C - Circulation</Typography>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginBottom: 2 }}
              onClick={() => {
                setFluidDialog(true);
              }}
            >
              Add fluid / Blood
            </Button>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {fluidList.map((elm, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <React.Fragment>
                          <Typography variant="button">
                            {elm.fluidtype}
                          </Typography>
                          <Typography variant="body2">
                            {elm.amount} ml
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <Typography variant="caption">
                          {dayjs(elm.time).format("DD/MM/YYYY HH:ss")}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
